import { ReactNode } from 'react';
import { Button, Space, Table } from 'antd';
import { isMobile } from 'react-device-detect';

import {
  IPatient,
  IPatientStudy,
  IStudyDownload,
} from '../../../store/patient';
import FileActionButtons from './FileActionButtons';
import IsoFileActionButtons from './IsoFileActionButtons';
import { ExportOutlined } from '@ant-design/icons';

const downloadsColumns = (
  study: IPatientStudy,
  handleFileShow: (studyUUID: string, fileUUID: string) => void,
  handleFileDownload: (
    studyName: string,
    studyUUID: string,
    file: IStudyDownload
  ) => void,
  handleFilePrepare: (studyUUID: string) => void,
  downloadFileLoading: string | null
) => {
  return [
    {
      dataIndex: 'type',
      key: 'type',
      width: isMobile ? '70%' : '40%',
    },
    {
      title: 'Action',
      key: 'action',
      render: (download: IStudyDownload) => {
        const isFilm = download.type === 'Плівка';
        const isIso = download.type === 'Образ диску';

        if (isIso) {
          return (
            <IsoFileActionButtons
              message={download.message}
              isoStatus={download.isoStatus}
              onFileDownload={() =>
                handleFileDownload(study.services, study.studyUUID, download)
              }
              onFilePrepare={() => handleFilePrepare(study.studyUUID)}
              downloadFileLoading={download.fileUUID === downloadFileLoading}
            />
          );
        }

        return (
          <FileActionButtons
            onFileDownload={() =>
              handleFileDownload(study.services, study.studyUUID, download)
            }
            onFileShow={
              isFilm
                ? undefined
                : () => handleFileShow(study.studyUUID, download.fileUUID)
            }
            downloadFileLoading={download.fileUUID === downloadFileLoading}
          />
        );
      },
    },
  ];
};

const StudyRows = (
  sharing: boolean,
  studyShareClick: (studyUUID: string) => void,
  handleFileShow: (studyUUID: string, fileUUID: string) => void,
  handleFileDownload: (
    studyName: string,
    studyUUID: string,
    file: IStudyDownload
  ) => void,
  downloadFileLoading: string | null,
  checkStudyImage: (study: IPatientStudy) => void,
  handleFilePrepare: (studyUUID: string) => void
) => {
  const handleExpand = (open: boolean, study: IPatientStudy) => {
    if (open) {
      checkStudyImage(study);
    }
  };

  return (row: IPatient): ReactNode => {
    return (
      <Table
        columns={[
          {
            dataIndex: 'date',
            key: 'date',
            width: 90,
          },
          {
            dataIndex: 'services',
            key: 'services',
            render: (text: string, study: IPatientStudy) =>
              sharing ? (
                <Space>
                  <span>{text}</span>
                  <Button
                    icon={<ExportOutlined />}
                    onClick={(e) => {
                      e.stopPropagation();
                      studyShareClick(study.studyUUID);
                    }}
                  />
                </Space>
              ) : (
                <span>{text}</span>
              ),
          },
        ]}
        size="small"
        onExpand={handleExpand}
        defaultExpandedRowKeys={[row.visits[0]?.studyUUID]}
        expandRowByClick
        locale={{ emptyText: 'Пусто' }}
        showHeader={false}
        dataSource={row.visits.map((s) => ({ ...s, key: s.studyUUID }))}
        expandedRowRender={(study: IPatientStudy): ReactNode => (
          <Table
            columns={downloadsColumns(
              study,
              handleFileShow,
              handleFileDownload,
              handleFilePrepare,
              downloadFileLoading
            )}
            size="small"
            showHeader={false}
            dataSource={study.downloads.map((s) => ({ ...s, key: s.fileUUID }))}
            pagination={false}
          />
        )}
        pagination={false}
      />
    );
  };
};

export default StudyRows;
